import React from 'react';
import clsx from 'clsx';
import { IconMoney } from '@ftdr/blueprint-components-react';
import styles from './promo-toaster.module.css';

interface IProps {
    promoAmount: number | null;
}

export const PromoToaster = ({ promoAmount }: IProps) => {
    return (
        <div className="text-white py-1 pl-2 pr-1 text-0 rounded-t-1 font-bold">
            <div className={clsx(styles['toaster-icon'])}>
                <IconMoney
                    className="inline absolute left-0 top-0"
                    color="white"
                    size="16"
                />
            </div>
            {`YOUR $${promoAmount} PROMO CODE HAS BEEN APPLIED`}
        </div>
    );
};
