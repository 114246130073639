import { Popover, Text } from '@ftdr/blueprint-components-react';
import reactStringReplace from 'react-string-replace';
import { IFeeContent } from '../../interfaces/zesty';
import { FormattedSymbols } from './formatted-symbols';

export { FormErrorMessage } from './form-error-message';
export { InputWithError } from './input-with-error';
export { BookingStepHeader } from './booking-step-header';
export { PromoToaster } from './promo-toaster';

const renderWithRollover = (description: string, feeContent?: IFeeContent) =>
    reactStringReplace(description, `%${feeContent?.mapper}%`, (match, i) => (
        <Popover
            key={i}
            placement="bottom"
            triggerInteraction="hover"
            content={
                <Text
                    className="max-w-sm bg-white p-2 shadow-floating rounded-4 text-1 leading-22px p-4 pc-popover z-50"
                    color="black"
                    variant="helper-text"
                >
                    {feeContent?.content}
                </Text>
            }
            className="inline-block"
        >
            <Text
                color="interactive"
                className="cursor-pointer underline inline-block"
            >
                {feeContent?.label}
            </Text>
        </Popover>
    ));

export const getRolloveredText = (
    description: string,
    feeContent?: IFeeContent,
) => {
    const hasRollover = description?.indexOf(`%${feeContent?.mapper}%`) > -1;

    if (hasRollover) {
        return renderWithRollover(description, feeContent);
    }
    return <FormattedSymbols text={description} />;
};
