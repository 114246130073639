import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getTealiumBaseData } from '../services';
import { ITealiumBaseDataValue } from '../services/tealium';
import { getMarketId, getServiceId } from '../shared/helpers';

type TealiumData = Record<string, string | number>;

interface ITealiumHookConfig {
    omitProps?: string[];
    zipCode?: string;
    marketId?: string;
    serviceTypeId?: string;
    defaultZipCode?: string;
}

function getDefaultBaseData() {
    return {
        funnel_design_type: 'React',
        proconnect_market_id: '999',
        proconnect_service_type_id: '999',
        customer_zip: 'None',
        page_targeted_location: 'No Location Preselected',
        page_targeted_service: 'No Service Preselected',
        proconnect_book_flow_type: 'No Location Preselected',
    };
}

function removeOmittedProps(
    omitProps: string[],
    baseData: ITealiumBaseDataValue,
) {
    const baseDataCopy = { ...baseData };
    for (const prop of omitProps) {
        delete baseDataCopy[prop as keyof ITealiumBaseDataValue];
    }

    return baseDataCopy;
}

export function useTealium({
    omitProps = [],
    marketId,
    serviceTypeId,
    defaultZipCode,
    zipCode = '',
}: ITealiumHookConfig = {}) {
    const [baseData, setBaseData] = useState<ITealiumBaseDataValue>(() =>
        removeOmittedProps(omitProps, getDefaultBaseData()),
    );
    const { query } = useRouter();
    const urlServiceId = query?.service_id as string | undefined;
    const marketIdVal = marketId || getMarketId(urlServiceId) || '';
    const serviceTypeIdVal = serviceTypeId || getServiceId(urlServiceId) || '';
    const defaultZipCodeVal =
        defaultZipCode || (query.zipCode as string | undefined) || '';

    useEffect(() => {
        async function getTealium() {
            const tealiumBaseData = await getTealiumBaseData({
                serviceTypeId: serviceTypeIdVal,
                marketId: marketIdVal,
                zipCode,
                defaultZipCode: defaultZipCodeVal,
            });
            setBaseData(removeOmittedProps(omitProps, tealiumBaseData));
        }

        getTealium();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceTypeId]);

    return {
        trackView: (data: TealiumData) => {
            if (baseData.proconnect_market_id === '999') {
                return false;
            }

            window.utag?.view({
                ...baseData,
                ...data,
            });
            return true;
        },
        trackEvent: (data: TealiumData, forceTrack = false) => {
            if (baseData.proconnect_market_id === '999' && !forceTrack) {
                return false;
            }

            window.utag?.link({
                ...baseData,
                ...data,
            });
            return true;
        },
        baseData,
    };
}
