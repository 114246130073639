export const FormattedSymbols = ({ text }: { text: string | undefined }) => {
    if (!text) {
        return null;
    }

    const textChunks = text.split('®');
    return (
        <>
            {textChunks.map((chunk, i) => {
                return (
                    <>
                        {chunk}
                        {i !== textChunks.length - 1 && <sup>®</sup>}
                    </>
                );
            })}
        </>
    );
};
