import { Rating, RatingValue, Text } from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import styles from './review.module.css';
interface Props {
    reviewer?: string;
    service?: string;
    paid?: number;
    content?: string;
    rating?: RatingValue;
    className?: string;
    isFTDRTheme?: boolean;
}

export function Review({
    reviewer,
    content,
    rating = 0,
    className,
    isFTDRTheme,
}: Props) {
    const reviewerStar = () => (
        <div className={clsx(isFTDRTheme && 'mt-5')}>
            <Text
                color="primary"
                variant={isFTDRTheme ? 'heading-04' : 'heading-05'}
                className="text-4 mb-1"
            >
                {reviewer}
            </Text>
            <div className={clsx(!isFTDRTheme && 'mt-3 mb-5')}>
                <Rating
                    value={rating}
                    className={styles['star']}
                    color="secondary"
                    readonly
                />
            </div>
        </div>
    );

    return (
        <div
            className={clsx(
                `bg-white text-left pb-12`,
                className,
                !isFTDRTheme && 'border border-solid border-gray-300',
                isFTDRTheme &&
                    `flex flex-col justify-between ${styles['review-box-ftdr']}`,
            )}
        >
            {!isFTDRTheme && reviewerStar()}
            <Text
                variant="body-short"
                className={clsx('leading-5', styles['review-text-ftdr'])}
            >
                &ldquo;{content}&rdquo;
            </Text>
            {isFTDRTheme && reviewerStar()}
        </div>
    );
}
