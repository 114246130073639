import { Text } from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import { FormErrorMessage } from './form-error-message';

interface IProps {
    title: string;
    errorMsg?: string;
    isFTDRTheme: boolean;
}

export const BookingStepHeader = ({ title, errorMsg, isFTDRTheme }: IProps) => {
    return (
        <>
            <Text
                variant="heading-05"
                className={clsx(
                    'text-4 text-25px my-4 text-primary-800 normal-case',
                    isFTDRTheme && 'mt-5 mb-6 md:mt-6 md:mb-7',
                )}
            >
                {title}
            </Text>
            {errorMsg && (
                <div className="mb-4 flex justify-center">
                    <FormErrorMessage errMsg={errorMsg} />
                </div>
            )}
        </>
    );
};
