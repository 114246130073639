import { getBaseServiceFactory } from '../base';
import { endpoints } from '../../shared/constants';
import { odmarketservicespb } from '../../protobuf/models/odmarketservicespb';
import * as marketService from '.';
import * as Sentry from '@sentry/nextjs';

export const odMarketClientBaseService = getBaseServiceFactory({
    endpoint: endpoints.marketByZipClient,
    protoResponseModel: odmarketservicespb.MarketByZipCodeResponse,
    useProto: true,
});

export async function getMarketByZipCodeClient(zipCode: string) {
    return new Promise<odmarketservicespb.MarketByZipCodeResponse | undefined>(
        (resolve, reject) => {
            marketService
                .odMarketClientBaseService({
                    param: zipCode,
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    Sentry.captureException(err, {
                        extra: {
                            offender: 'getMarketByZipCodeClient',
                        },
                    });

                    reject('Error getting Market');
                });
        },
    );
}
