import { Input, InputProps } from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import { FormErrorMessage } from './form-error-message';

interface IProps extends InputProps {
    showErrorBorder?: boolean;
    showErrorMsg: boolean;
    errorMsg?: string;
}

export const InputWithError = ({
    showErrorBorder,
    showErrorMsg,
    errorMsg,
    className,
    ...props
}: IProps) => {
    return (
        <>
            <Input
                {...props}
                className={clsx(className, {
                    'border border-error-500 rounded-2': showErrorBorder,
                })}
            />
            {showErrorMsg && errorMsg ? (
                <FormErrorMessage errMsg={errorMsg} />
            ) : null}
        </>
    );
};
