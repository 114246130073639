import React from 'react';
import {
    Message,
    IconWarningTriangleFull,
} from '@ftdr/blueprint-components-react';

interface IProps {
    errMsg: string;
}
const FormErrorMessage = ({ errMsg }: IProps) => {
    return errMsg ? (
        <Message
            className="text-left ml-1 mt-1"
            status="error"
            size="small"
            showStatusLabel={false}
            matchColor={true}
            icon={<IconWarningTriangleFull />}
        >
            {errMsg}
        </Message>
    ) : null;
};

export { FormErrorMessage };
