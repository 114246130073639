import { useContext, useEffect, useState } from 'react';
import { IMarket } from '../interfaces/shared';
import { getGeoLocation } from '../services';
import { getMarketByZipCodeClient } from '../services/market-client';
import { PagesContext } from '../state/pages.context';

const useGeolocation = () => {
    const [market, setMarket] = useState<IMarket>();
    const { userMarket } = useContext(PagesContext);

    useEffect(() => {
        if (!userMarket || Object.keys(userMarket).length < 1) {
            getGeoLocation()
                .then((response) => {
                    const message = response.message.split(',');
                    const res = message.reduce(
                        (acc: { [x: string]: any }, curr: string) => (
                            (acc[curr.split('=')[0]] = curr.split('=')[1]), acc
                        ),
                        {},
                    );

                    const zip = res.zip.slice(0, 5);

                    //Todo add what to default to if this call fails to get a zip
                    getMarketByZipCodeClient(zip)
                        .then((response) => {
                            setMarket({
                                id: response?.superMarket?.marketLegacyId?.toString()
                                    ? response?.superMarket?.marketLegacyId?.toString()
                                    : '1',
                                name:
                                    response?.superMarket?.marketName ||
                                    'Los Angeles',
                            });
                        })
                        .catch((err) => {
                            console.error(
                                'There was an error getting the geolocation market from zip: ',
                                err,
                            );
                            setMarket({
                                id: '1',
                                name: 'Los Angeles',
                            });
                        });
                })
                .catch((err) => {
                    console.error(
                        'There was an error geting the geolocation header: ',
                        err,
                    );
                });
        }
    }, []);

    return { market };
};

export { useGeolocation };
