import {
    RatingValue,
    Text,
    useIsMobile,
} from '@ftdr/blueprint-components-react';
import _ from 'lodash';
import { Review } from '../review';
import styles from './reviews.module.css';
import { IReview, IServiceReviews } from '../../interfaces/zesty';
import clsx from 'clsx';

export function Reviews({
    headline,
    reviews,
    backgroundColorProp,
    noMargins,
    isFTDRTheme,
}: IServiceReviews) {
    const isMobile = useIsMobile();

    reviews = reviews || [];

    const renderReviews = (reviews: IReview[]) => {
        const reviewToDisplay =
            reviews.length > 3
                ? _.sortBy(reviews, ['sortOrder']).slice(0, 3)
                : _.sortBy(reviews, ['sortOrder']);
        return (reviewToDisplay as Array<IReview>)?.map((review, i) => (
            <Review
                key={i}
                className={clsx(
                    'flex-1 p-4 overflow-x-auto',
                    !isFTDRTheme && 'mx-2 my-1',
                    isFTDRTheme && 'mx-4 md:mx-5 mb-6',
                )}
                reviewer={`${review.firstName} ${review.lastName}`}
                service={review.jobTitle}
                paid={review.amountPaid}
                content={review.review}
                rating={review.rating as RatingValue}
                isFTDRTheme={isFTDRTheme}
            />
        ));
    };

    return (
        <div
            style={{ backgroundColor: backgroundColorProp }}
            className={clsx(
                reviews.length > 2 ? `block text-center mx-auto` : 'hidden',
                reviews.length > 2 && !isFTDRTheme && 'pb-8',
                noMargins ? '' : !isFTDRTheme && 'md:mt-10',
                isFTDRTheme && 'pt-1 pb-6 md:pb-20 reviews-ftdr',
            )}
        >
            <div className="mx-auto md:w-3/5 w-4/5">
                <Text
                    variant="heading-03"
                    color="secondary"
                    className={clsx(
                        'text-center text-6 capitalize',
                        !isFTDRTheme && 'mb-6 md:mb-7',
                        !isFTDRTheme && noMargins && 'pt-10 pb-4',
                        !isFTDRTheme &&
                            !noMargins &&
                            'pt-8 mt-11 md:mt-0 md:pt-9',
                        isFTDRTheme && 'mt-9 mb-9 md:mt-12 md:mb-9 md:pt-0',
                    )}
                >
                    {isFTDRTheme
                        ? isMobile
                            ? 'Reviews for In-Person services'
                            : 'Frontdoor Pro Reviews For In-Person Services'
                        : headline}
                </Text>
            </div>
            <div
                className={clsx(
                    `flex flex-col mx-4 md:flex-row ${styles['review-content']}`,
                    isFTDRTheme && styles['review-content-ftdr'],
                )}
            >
                {renderReviews(reviews)}
            </div>
        </div>
    );
}
