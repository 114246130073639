import { Text, Link } from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import { IServiceGuarantees } from '../../interfaces/zesty';
import styles from './guarantee.module.css';

const Guarantee = ({ guarantees = [], isFTDRTheme }: IServiceGuarantees) => {
    return (
        <div
            className={clsx(
                'pb:mb-32',
                !isFTDRTheme && 'pt-20 pb-24 bg-fd-lg',
                isFTDRTheme && 'pt-16 pb-20 guarantee-ftdr',
            )}
        >
            <div
                className={clsx(
                    styles['container'],
                    'md:flex md:space-x-12 md:mx-auto md:justify-center',
                )}
            >
                {guarantees.map((x, i) => (
                    <div key={i} className="text-center">
                        <div>
                            <img
                                src={x.image}
                                alt={x.title}
                                width="120"
                                height="142"
                                style={{ width: '120px', height: '142px' }}
                                className="m-auto"
                            />
                        </div>
                        <div
                            className={clsx(
                                'mx-auto',
                                styles['content-wrapper'],
                            )}
                        >
                            <Text
                                variant="heading-04"
                                color="secondary"
                                className="mt-4 mb-2 md:mt-8 md:mb-3 uppercase"
                            >
                                {x.title}
                            </Text>

                            <Text>
                                <div className="max-w-3xl">
                                    {x.descriptionTexts}{' '}
                                    {x.link && x.linkText && (
                                        <Link
                                            underline="always"
                                            color="interactive"
                                            href={x.link}
                                            className="inline-block mt-2"
                                        >
                                            {x.linkText}
                                        </Link>
                                    )}
                                </div>
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { Guarantee };
